import React from 'react';
import './Dependency.css';

export default class Dependency extends React.Component {
    render(){
        if(this.props.value.status === "OK" || this.props.value.status === "200 OK"){
            return <div>
                {this.props.value.name} : <span className="OkStatus">OK</span>
            </div>
        } else {
            return <div>
                {this.props.value.name} : <span className="KoStatus">{this.props.value.status}</span>
            </div>
        }
        
    }
}