import React from "react";
import ServiceStatus from "../ServiceStatus/ServiceStatus";
import "./ServicesStatus.css";
import { endpoints } from "../../api/endpoints";
import ApiCall from "../../api/api";

export default class StatusServices extends React.Component {
  constructor(props) {
    super(props);

    // Call Heartbeat-Service
    this.getHeartbeatStatus()
      .then((response) => {
        return response.data;
      })
      .then((data) => this.updateStatus(data));

    this.state = {
      status: "Loading", //result
    };
  }

  updateStatus(data) {
    this.setState({ status: data });
  }

  render() {
    if (this.state.status === "Loading") {
      return (
        <div>
            <p><i>Vérification des statuts ... </i></p>
            <p>Si cela prends trop de temps merci de rafraîchir la page.</p>
        </div>
      );
    } else {
      return (
        <div className="ServicesStatus">
          {this.state.status.map((service, index) => {
            return <ServiceStatus key={index} service={service} />;
          })}
        </div>
      );
    }
  }

  getHeartbeatStatus() {
    try {
      return ApiCall.get(endpoints().HEARTBEAT);
    } catch (err) {
      console.error(err);
    }
  }
}
