import React from 'react';
import Dependency from '../Dependency/Dependency';
import './ServiceStatus.css';

export default class ServiceStatus extends React.Component {
    render(){
        return <div className="ServiceStatus">
            <div className="ServiceName">{this.props.service.serviceName}</div>
            {/* <div>Dépendances:</div> */}
            <div className="Dependencies">
                {this.props.service.finalHeart.heatBeat.map((dependency, index) => <Dependency key={index} value={dependency}/>)}
            </div>
        </div>
    }
}