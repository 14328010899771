import ApiCall from './api';
import { endpoints } from './endpoints';

export const getUserInfo = async () => {
  return new Promise((resolved, reject) => {
    ApiCall.get(endpoints().USERINFO)
      .then(response => {
        resolved(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}