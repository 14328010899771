import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { filter } from "rxjs/operators";

import { authConfig } from "./auth.config";
import AuthService from "./services/auth.service";
import { OAuthSuccessEvent, OAuthErrorEvent } from "./services/auth.event";
import { getUserInfo } from './api/users';

// OAuth2 service configuration.
AuthService.configure(authConfig);
// Store token in browser session storage.
AuthService.setStorage(sessionStorage);

// Subscribe to OAuth2 service event.
AuthService.events.subscribe((event) => {
  event instanceof OAuthErrorEvent
    ? console.error(event)
    : // eslint-disable-next-line no-console
      console.debug(event);
});

// Check access token is received.
AuthService.events
  .pipe(filter((e: OAuthSuccessEvent) => e.type === "token_received"))
  .subscribe(() => {
    // Update user info.
    getUserInfo().then((user => {
        AuthService.setUserInfo(user);

        renderApp();
      }));
  });

// Check OAuth 2.0 token access.
AuthService.tryLogin()
  .then(() => {
    // Check access token validity.
    if (!AuthService.hasValidAccessToken()) {
      // Go to authentication provider login page.
      AuthService.getAuthentication();
    } else {
      // Render application.
      AuthService.setAuthentication();
      renderApp();
    }
  })
  .catch((err) => {
    console.error(err);
  });

// React Render
function renderApp(){
  if (AuthService.isUserLoggedIn()){
    ReactDOM.render(<App />, document.getElementById("root"));
  }
}
