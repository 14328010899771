/**
 * Check if object is null or undefined.
 * @param {*} obj
 */
export const isNullOrUndefined = obj => {
    return typeof obj === 'undefined' || obj === null;
  };

  /**
   * Check if an object is empty.
   * @param {*} obj
   */
  export const isEmpty = obj => {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }
