export class OAuthEvent {
    /**
     * @param {string} type
     */
    constructor(type) {
      this.type = type;
    }
  }

  /**
   * @param {string} type Success type label.
   * @param {object=} info Result info success.
   */
  export class OAuthSuccessEvent extends OAuthEvent {
    constructor(type, info = null) {
      super(type);
      this.info = info;
    }
  }

  /**
   * @param {string} type Error type label.
   * @param {object} reason Error object.
   * @param {object=} params
   */
  export class OAuthErrorEvent extends OAuthEvent {
    constructor(type, reason, params = null) {
      super(type);
      this.reason = reason;
      this.params = params;
    }
  }
